import React from "react";
import { useTranslation } from "react-i18next";

const data = [
  {
    title_en: "AI-prop featured in dot.B as a next-generation AI solution.",
    title_ja: "dot.Bに次世代AIソリューションとして掲載されました。",
    date: "2025.2.3",
    content:
      "In recent years, the rapid advancement of AI technology has enabled many companies to enhance operational efficiency and improve customer experiences. AI-powered solutions have brought about significant transformations across various industries, helping companies strengthen their competitive edge. This article explores how the adoption of AI technologies contributes to business growth, accompanied by real-world examples of successful implementations.",
    url: "https://www.dotbglobal.com/topics/industrial-transformation-with-next-generation-ai-solutions/",
    cover:
      "https://www.dotbglobal.com/wp-content/uploads/2024/11/thumbnail-38-870x460.jpg",
  },
];
export const News = (props) => {
  const { t, i18n } = useTranslation();
  //get current language
  const currentLanguage = i18n.language; // This will give you the current language code (e.g., 'en', 'ja')
  return (
    <section id="faq">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>{t(`news_title`)}</span>
          </div>
        </h2>
        {data.map((item, index) => {
          return (
            <dl
              key={index}
              className={"ac_list "}
              onClick={() => window.open(item.url, "_blank")}
            >
              <dt className="ac_head">
                <a href={item.url}>{item.date} </a>
                <p
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  {currentLanguage === "en-US" || currentLanguage === "en"
                    ? t(item.title_en)
                    : t(item.title_ja)}
                </p>
                <i className="cliper_toggle_icon fa fa-angle-right"></i>
              </dt>
              {/* <dd className="ac_body">
                <p>{t(item.content)}</p>
              </dd> */}
            </dl>
          );
        })}
      </div>
    </section>
  );
};
