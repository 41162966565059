import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Reviews } from "./components/reviews";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import { StartNow } from "./components/startNow";
import SmoothScroll from "smooth-scroll";
import { HowItWork } from "./components/howItWork";
import { ToastContainer } from "react-toastify";
import { Sample } from "./components/samples";
import { TryPhoto } from "./components/tryPhoto";
import { FreePhotoEdit } from "./components/freePhotoEdit";
import "./App.css";
import "swiper/swiper-bundle.css";
import "react-toastify/dist/ReactToastify.css";
import { FAQ } from "./components/faq";
import { WhyUs } from "./components/whyUs";
import { Pricing } from "./components/pricing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer } from "./components/footer";
import { PrivacyPolicy } from "./components/privacyPolicy";
import { TermOfService } from "./components/termOfService";
import { ComingSoon } from "./components/comingSoon";
import { Law } from "./components/law";
import { Settlement } from "./components/settlement";
import {
  COMING_SOON_MODE,
  GA_MEASUREMENT_ID,
  GTM_CONTAINER_ID,
} from "./constants";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { News } from "./components/news";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: 100,
});
ReactGA.initialize(GA_MEASUREMENT_ID);
TagManager.initialize({ gtmId: GTM_CONTAINER_ID });
const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const HomeApp = () => {
    return (
      <>
        <Helmet>
          <title>{t("general_title")}</title>
          <meta name="description" content={t("general_description")} />
          <meta name="keywords" content={t("general_keywords")} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Header data={landingPageData.Header} />
        <StartNow />
        <WhyUs />
        <TryPhoto />
        <Services data={landingPageData.Services} />
        <HowItWork />
        <Pricing />
        <Sample />
        <About data={landingPageData.About} />
        <FAQ />
        <Reviews />
        <News />
        <Contact data={landingPageData.Contact} />
      </>
    );
  };

  const FreePage = () => {
    return (
      <>
        <Helmet>
          <title>{t("general_title")}</title>
          <meta name="description" content={t("general_description")} />
          <meta name="keywords" content={t("general_keywords")} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>

        <FreePhotoEdit />
      </>
    );
  };
  return (
    // <Helmet>
    // {/* <title>{t("general_title")}</title>
    // <meta name="description" content={t("general_description")} />
    // <meta name="keywords" content={t("general_keywords")} /> */}
    <Router>
      {!COMING_SOON_MODE && <Navigation />}
      <Routes>
        <Route
          path="/"
          element={COMING_SOON_MODE ? <ComingSoon /> : <HomeApp />}
        />
        <Route path="/free-photo-editing" element={<FreePage />} />
        <Route path="/operation" element={<Law />} />
        <Route path="/credit-guidance" element={<Settlement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-of-service" element={<TermOfService />} />
      </Routes>
      {!COMING_SOON_MODE && <Footer />}

      <ToastContainer />
      <div
        className="modal fade"
        id="youtubeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="youtubeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-youtube" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  width="560"
                  height="315"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                  src="https://www.youtube.com/embed/UShZKUX1i-Q?si=NEJY39_h7EvkU3tO&amp;controls=1&autoplay=0&rel=0"
                  className="lazyloaded"

                  // src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
    // </Helmet>
  );
};

export default App;
