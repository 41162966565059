import React from "react";
import chatIcon from "../assets/header/chat.svg";
import { useTranslation } from "react-i18next";
import { CLIENT_PORTAL } from "../constants";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
export const StartNow = (props) => {
  const { t } = useTranslation();
  return (
    <div id="startNow">
      <div className="inner row">
        <div className="col col-xs-12 col-md-12">
          <div className="txtinput">
            <img src={chatIcon} alt="" width={"60px"} />
            <div className="title forPC">
              <span> {t(`start_now_title`)}</span>
            </div>
          </div>
          <div className="btns">
            <Link
              className="btn "
              to="/free-photo-editing"
              onClick={() => {
                ReactGA.event({
                  category: "Landing Page",
                  action: "Click",
                  label: "Get Start Free Button",
                });
              }}
            >
              <span> {t(`free_usage`)}</span>

              <span className="sub"> {t(`free_usage_btn_sub`)}</span>
            </Link>
            <div
              className="btn"
              onClick={() => {
                window.location.href = CLIENT_PORTAL;
                ReactGA.event({
                  category: "Landing Page",
                  action: "Click",
                  label: "Get Start Now Button",
                });
              }}
            >
              <span> {t(`start_now_btn`)}</span>
              <span className="sub"> {t(`start_now_btn_sub`)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
