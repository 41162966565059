import React from "react";
import logo from "../assets/about/logo_aleads.svg";
import { useTranslation } from "react-i18next";
import star from "../assets/about/star.svg";
import photo from "../assets/about/photo.svg";
import year from "../assets/about/year.svg";
import client from "../assets/about/client.svg";
import propy from "../assets/about/pro-py.svg";
import tanomo from "../assets/about/tanomo.jpg";
import mdrTanomo from "../assets/about/mdr_tanomo.png";
import kirimuki from "../assets/about/kirimuki_tamono.png";
import top from "../assets/about/top_tanomo.png";
import map from "../assets/about/map_tanomo.png";
import toroku from "../assets/about/toroku_tanomo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
  Scrollbar,
  Mousewheel,
} from "swiper/modules";
const eco_system = [
  {
    image: propy,
    link: "https://pro-py.com/",
    label: "pro-py",
  },
  {
    image: tanomo,
    link: "https://photo.tanomo.asia/",
    label: "tanomo",
  },
  {
    image: mdrTanomo,
    link: "https://tanomo.asia/",
    label: "mdr_tanomo",
  },
  {
    image: kirimuki,
    link: "https://kirinuki.tanomo.asia/",
    label: "kirinuki",
  },
  {
    image: top,
    link: "https://top.tanomo.asia/",
    label: "top",
  },
  {
    image: map,
    link: "https://map.tanomo.asia/",
    label: "map",
  },
  {
    image: toroku,
    link: "https://toroku.tanomo.asia/",
    label: "toroku",
  },
];
const data = [
  {
    title: "about_our_member",
    number: "100 +",
    icon: star,
  },
  {
    title: "about_our_photo",
    number: "3,000,000 +",
    icon: photo,
  },
  {
    title: "about_our_year",
    number: "10 +",
    icon: year,
  },
  {
    title: "about_our_client",
    number: "1,000 +",
    icon: client,
  },
  {
    title: "about_our_member",
    number: "100 +",
    icon: star,
  },
  {
    title: "about_our_photo",
    number: "3,000,000 +",
    icon: photo,
  },
  {
    title: "about_our_year",
    number: "10 +",
    icon: year,
  },
  {
    title: "about_our_client",
    number: "1,000 +",
    icon: client,
  },
];
export const About = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <section id="about">
        <div className="contInner">
          <h2 className="hdg_ttl02">
            <div>
              <span>{t(`about_title`)}</span>
            </div>
          </h2>
          <div className="inner">
            <div className="left">
              <img src={logo} alt="logo A-Leads" />
            </div>
            <div className="right">
              {t(`about_des_1`)
                .split("\n")
                .map((line, ind) => (
                  <React.Fragment key={ind}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              {t(`about_des_2`)
                .split("\n")
                .map((line, ind) => (
                  <React.Fragment key={ind}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              {t(`about_des_3`)
                .split("\n")
                .map((line, ind) => (
                  <React.Fragment key={ind}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section id="cont07">
        <div className="contInner">
          <div className="swiperWrap">
            <Swiper
              className="swiper07"
              modules={[
                A11y,
                Autoplay,
                EffectFade,
                Pagination,
                Mousewheel,
                Navigation,
                Scrollbar,
              ]}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              navigation={{
                enabled: true,
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              loop={true}
              effect={"slide"}
              speed={1500}
              spaceBetween={30}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 4, // Desktop view
                },
              }}
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="txt">{t(item.title)}</div>
                  <div className="num"> {item.number}</div>
                  <div className="ico">
                    <img src={item.icon} alt="icon" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>
      </section>
      <section id="cont10">
        <div className="contInner">
          <h2 className="hdg_ttl02">
            <div>
              <span>{t(`about_eco_system`)}</span>
            </div>
          </h2>
          <div className="swiperWrap">
            <Swiper
              className="swiper10"
              modules={[
                A11y,
                Autoplay,
                EffectFade,
                Pagination,
                Mousewheel,
                Navigation,
                Scrollbar,
              ]}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              navigation={{
                enabled: true,
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              loop={true}
              effect={"slide"}
              speed={1500}
              spaceBetween={30}
              slidesPerView={2}
              breakpoints={{
                768: {
                  slidesPerView: 5,
                },
              }}
            >
              {eco_system.map((item, index) => (
                <SwiperSlide key={index}>
                  <a
                    href={item.link}
                    className="ico"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.image} alt="icon" />
                    <span>{t(item.label)}</span>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};
