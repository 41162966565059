/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import example1 from "../assets/tryPhoto/01-before.jpg";
import example2 from "../assets/tryPhoto/02-before.jpg";
import example3 from "../assets/tryPhoto/03-before.jpg";
import example4 from "../assets/tryPhoto/04-before.jpg";
import output1 from "../assets/tryPhoto/01-after.jpeg";
import output2 from "../assets/tryPhoto/02-after.jpeg";
import output3 from "../assets/tryPhoto/03-after.jpeg";
import output4 from "../assets/tryPhoto/04-after.jpeg";
import guardduty from "../assets/tryPhoto/guardduty.png";
import exampleBefore from "../assets/tryPhoto/example_before.jpeg";
import exampleAfter from "../assets/tryPhoto/example_after.jpeg";
import white_balance_before from "../assets/services/white-balance-before.jpeg";
import white_balance_after from "../assets/services/White-Balance-Adjustment-After.jpg";
import brightness_before from "../assets/services/Brightness-and-contrast-adjustment-Before.jpg";
import brightness_after from "../assets/services/Brightness-and-contrast-adjustment-After.jpg";
import horizontal_vertical_before from "../assets/services/Horizontal-And-Vertical-Distortion-Modification-Before.jpg";
import horizontal_vertical_after from "../assets/services/Horizontal-And-Vertical-Distortion-Modification-After.jpg";
import originalImg from "../assets/pricing/n__DSC_0074_Original.jpeg";
import aiImg from "../assets/pricing/n__DSC_0074_AI.jpeg";
import submitImg from "../assets/pricing/n__DSC_0074_Submit.jpeg";
import Dropzone from "react-dropzone";
import { services as allServices } from "./services";
import { Swiper, SwiperSlide } from "swiper/react";

import { CLIENT_PORTAL } from "../constants";
import {
  A11y,
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
  Scrollbar,
  Mousewheel,
} from "swiper/modules";
import axios from "axios";
import {
  BACKEND_URL,
  PHOTO_ENHANCE_SERVICE,
  PHOTO_MAX_SIZE,
  PHOTO_MIN_SIZE,
  PHOTO_MAX_TIME_TRY,
} from "../constants";
import { toast } from "react-toastify";
import ReactCompareImage from "react-compare-image";
import ReactGA from "react-ga4";

import { InviteUserModal } from "./inviteUserModal";
const exampleData = [
  {
    input: example1,
    output: output1,
  },
  {
    input: example2,
    output: output2,
  },
  {
    input: example4,
    output: output4,
  },
  {
    input: example3,
    output: output3,
  },
];
export const services = [
  {
    name: "service_white_balance",
    description: "service_white_balance_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-brightness-low",
    isDefault: true,
    before: white_balance_before,
    after: white_balance_after,
  },
  {
    name: "service_brightness",
    description: "service_brightness_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-triangle-half",
    isDefault: true,
    before: brightness_before,
    after: brightness_after,
  },
  {
    name: "service_horizontal_vertical",
    description: "service_horizontal_vertical_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-rulers",
    isDefault: true,
    before: horizontal_vertical_before,
    after: horizontal_vertical_after,
  },
];

export const FreePhotoEdit = (props) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState("upload");
  const [beforeImg, setBeforeImg] = useState(exampleBefore);
  const [afterImg, setAfterImg] = useState(exampleAfter);
  const [currentAction, setCurrentAction] = useState("wait");
  const dropzoneRef = React.useRef();

  const processPhoto = (file) => {
    if (getCurrentTries() >= PHOTO_MAX_TIME_TRY) {
      return;
    }
    setMode("loading");
    setBeforeImg(URL.createObjectURL(file));
    setAfterImg(URL.createObjectURL(file));

    const formData = new FormData();

    formData.append("file", file);
    formData.append("serviceId", PHOTO_ENHANCE_SERVICE);
    axios
      .post(BACKEND_URL + "/api/v1/public/trials", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAfterImg(response.data.result);
          setMode("download");
          incrementTries();
        } else {
          toast.error(t("try_photo_error"));
          setMode("upload");
        }
        // assign image to the target element
      })
      .catch((error) => {
        toast.error(t("try_photo_error"));
        setMode("upload");
      });
  };
  const processExamplePhoto = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (getCurrentTries() >= PHOTO_MAX_TIME_TRY) {
      return;
    }
    setMode("loading");
    setBeforeImg(item.input);
    setAfterImg(item.output);
    const randomTimeout = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
    setTimeout(() => {
      setAfterImg(item.output);
      setMode("download");
      incrementTries();
    }, randomTimeout);
  };

  const handleActionClick = () => {
    const action = currentAction;
    ReactGA.event({
      category: "Landing Page",
      action: "Click",
      label: "Get try photo with action:" + action,
    });

    if (action === "upload") {
      setMode("upload");
      const currentTries = getCurrentTries();
      if (currentTries >= PHOTO_MAX_TIME_TRY) {
        toast.error(t("try_photo_mes_max_try"));
        return;
      }
      //add dropzone click event
      dropzoneRef?.current?.open();
    }
    if (action === "download") {
      //download after image
      const link = document.createElement("a");
      link.href = afterImg;
      link.download = "enhanced.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setMode("upload");
    }
    setCurrentAction("wait");
  };
  const getCurrentTries = () => {
    const triesData = JSON.parse(localStorage.getItem("triesData")) || {
      date: null,
      tries: 0,
    };
    const currentDate = new Date().toISOString().split("T")[0];

    if (triesData.date === currentDate) {
      return triesData.tries;
    } else {
      // Reset the tries if the current date is not the same as the date in local storage
      localStorage.setItem(
        "triesData",
        JSON.stringify({ date: currentDate, tries: 0 })
      );
      return 0;
    }
  };
  const incrementTries = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTries = getCurrentTries();

    localStorage.setItem(
      "triesData",
      JSON.stringify({ date: currentDate, tries: currentTries + 1 })
    );
  };
  return (
    <section id="try_photo" className="full-height">
      <div className="contInner">
        <h2 className="hdg_ttl02" style={{ marginTop: "100px" }}>
          <div>
            <span>
              <span className="c">{t(`free_photo_title_2`)}</span>
              <br className="break_header_line" />
              {t(`free_photo_title_3`)}
            </span>
          </div>
        </h2>
        <div className="txt sub-title ">
          <p>
            {t(`free_photo_sub_title`)
              .split("\n")
              .map((line, ind) => (
                <React.Fragment key={ind}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </p>
          {mode === "upload" && (
            <p className="sub-title-2">
              {t(`free_photo_sub_title_2`)
                .split("\n")
                .map((line, ind) => (
                  <React.Fragment key={ind}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          )}
          {mode === "download" && (
            <p
              className={
                "sub-title-2 " + (mode === "download" ? "download-note" : "")
              }
            >
              {t(`free_photo_sub_title_completed`)
                .split("\n")
                .map((line, ind) => (
                  <React.Fragment key={ind}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          )}
        </div>
        <div className="wrap">
          <div className="right free-uploader">
            {mode === "upload" && (
              <div className="codeWrap ">
                <Dropzone
                  disabled={
                    mode === "loading" ||
                    getCurrentTries() >= PHOTO_MAX_TIME_TRY
                  }
                  ref={dropzoneRef}
                  accept={{ "image/*": [".jpeg", ".png"] }}
                  minSize={PHOTO_MIN_SIZE}
                  maxSize={PHOTO_MAX_SIZE}
                  maxFiles={1}
                  onDrop={(acceptedFiles, fileRejections) => {
                    // Handle the rejected files
                    if (fileRejections && fileRejections.length > 0) {
                      fileRejections.forEach(({ file, errors }) => {
                        errors.forEach((e) => {
                          toast.error(t(e.code));
                        });
                      });
                      return;
                    }
                    // Handle the accepted files
                    processPhoto(acceptedFiles[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="drag">
                          <div
                            id="btnProcess"
                            className={
                              getCurrentTries() >= PHOTO_MAX_TIME_TRY
                                ? "disabled"
                                : ""
                            }
                          >
                            <span>{t(`free_photo_upload`)}</span>
                          </div>
                          <span>
                            {getCurrentTries() >= PHOTO_MAX_TIME_TRY
                              ? t("try_photo_mes_max_try")
                              : t("try_photo_mes_drop")
                                  .split("\n")
                                  .map((line, ind) => (
                                    <React.Fragment key={ind}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                          </span>
                          {/* <div className="service-list">
                            <div className="service-row">
                              {services.map((service) => (
                                <button
                                  data-toggle="modal"
                                  data-target="#inviteUserModal"
                                  key={service.name}
                                  className={`service-item ${
                                    service.isDefault ? "active" : "inactive"
                                  }`}
                                >
                                  <i className={`bi ${service.icon}`} />
                                  <span className="service-name">
                                    {t(service.name)}
                                  </span>
                                </button>
                              ))}
                            </div>
                          </div> */}
                          <div className="forClick d-flex justify-content-center">
                            <span>☟</span>
                            <span>
                              {t(`try_photo_mes_quick_try`)
                                .split("\n")
                                .map((line, ind) => (
                                  <React.Fragment key={ind}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}{" "}
                            </span>
                            <span>☟</span>
                          </div>
                          <div className="photoItems">
                            <div className="item">
                              {exampleData.map((item, index) => {
                                return (
                                  <div
                                    className="i"
                                    key={index}
                                    onClick={(e) =>
                                      processExamplePhoto(item, e)
                                    }
                                  >
                                    <img src={item.input} alt="" />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="photoHere " style={{ color: "red" }}>
                            <span>{t(`try_photo_note_2`)}</span>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="malware-protection">
                  <img
                    src={guardduty}
                    alt="Malware Protection"
                    className="protection-icon"
                  />
                </div>
              </div>
            )}
            {mode === "loading" && (
              <div className="left">
                <img className="process_image" src={beforeImg} alt="" />
                <img
                  className="process_loading"
                  style={{ width: "25px !important" }}
                  src="./css/nivo-lightbox/loading.gif"
                  alt="Loading..."
                />
              </div>
            )}
            {/* {mode === "download" && (
              <div className="service-list">
                <div className="service-row">
                  {allServices.map((service) => (
                    <button
                      data-toggle="modal"
                      data-target="#inviteUserModal"
                      data-tooltip-position="top"
                      key={service.name}
                      type="button"
                      data-tooltip={t(service.name)}
                      className={`service-item service-icon ${
                        service.isDefault ? "active" : "inactive"
                      }`}
                    >
                      <i className={`bi ${service.icon}`} />
                    </button>
                  ))}
                </div>
              </div>
            )} */}
            {mode === "download" && (
              <div className="left">
                <ReactCompareImage
                  className="compare_image"
                  leftImage={beforeImg}
                  rightImage={afterImg}
                />
              </div>
            )}

            {mode === "download" && (
              <div className="button-container">
                <div className="action-buttons">
                  <button
                    onClick={() => setCurrentAction("download")}
                    className="action-button download-button"
                    data-toggle="modal"
                    data-target="#inviteUserModal"
                  >
                    <span>{t(`try_photo_download_btn`)}</span>
                    <img
                      className="button-icon"
                      src="./css/nivo-lightbox/download-icon.svg"
                      alt=""
                    />
                  </button>

                  <button
                    onClick={() => setCurrentAction("upload")}
                    data-toggle="modal"
                    data-target="#inviteUserModal"
                    className="action-button retry-button"
                  >
                    <span>
                      {t(`try_photo_try_again_btn`)}(
                      {PHOTO_MAX_TIME_TRY - getCurrentTries()})
                    </span>
                    <img
                      className="button-icon"
                      src="./css/nivo-lightbox/retry-icon.svg"
                      alt="retry"
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {mode === "upload" && (
          <div class="txt ">
            <span>
              {t(`free_photo_setting`)
                .split("\n")
                .map((line, ind) => (
                  <React.Fragment key={ind}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </span>
          </div>
        )}

        {mode === "upload" && (
          <div className="service-cards">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 col-sm-6 col-xs-12" key={service.name}>
                  <div className="service-card">
                    <h3 className="service-title">{t(service.name)}</h3>
                    <div className="compare-container">
                      <ReactCompareImage
                        leftImage={service.before}
                        rightImage={service.after}
                        sliderPositionPercentage={0.5}
                      />
                    </div>

                    <p className="service-description">
                      {t(service.description)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* {mode === "download" && (
          <div id="free_usage_all_service">
            <div class="txt ">
              <p>{t(`free_photo_service_title`)}</p>
              <p>{t(`free_photo_service_sub_title`)}</p>
            </div>
            <div className="swiperWrap">
              <Swiper
                className="swiper05"
                modules={[
                  A11y,
                  Autoplay,
                  EffectFade,
                  Pagination,
                  Mousewheel,
                  Navigation,
                  Scrollbar,
                ]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                navigation={{
                  enabled: true,
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                loop={true}
                effect={"slide"}
                speed={1500}
                spaceBetween={30}
                allowTouchMove={false}
                slidesPerView={1}
                breakpoints={{
                  1024: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  320: {
                    slidesPerView: 1,
                  },
                }}
              >
                {allServices.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      padding: "6px",
                    }}
                  >
                    <div className="service-card">
                      <h3 className="service-title">{t(item.name)}</h3>
                      <div className="compare-container">
                        <ReactCompareImage
                          leftImage={item.before}
                          rightImage={item.after}
                          sliderPositionPercentage={0.5}
                        />
                      </div>

                      <p className="service-description">
                        {t(item.description)}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
             
            </div>
          </div>
        )} */}
        {/* {mode === "download" && (
          <div id="free_usage_service">
            <div class="txt ">
              <p>{t(`free_photo_quality_title`)}</p>
              <p>
                {t(`free_photo_quality_sub_title`)
                  .split("\n")
                  .map((line, ind) => (
                    <React.Fragment key={ind}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            </div>
            <div className="ph">
              <div className="item">
                <img src={originalImg} alt="" />
                <span>{t(`service_origin`)}</span>
              </div>
              <div className="item">
                <img src={aiImg} alt="" />
                <span>{t(`service_ai_only`)}</span>
              </div>
              <div className="item">
                <img src={submitImg} alt="" />
                <span>{t(`service_submit`)}</span>
              </div>
            </div>
          </div>
        )} */}
        {/* {mode === "download" && (
          <div
            className="btn"
            onClick={() => {
              window.location.href = CLIENT_PORTAL;
              ReactGA.event({
                category: "Landing Page",
                action: "Click",
                label: "Get Start Now Button",
              });
            }}
          >
            <span> {t(`free_photo_sign_up_btn`)}</span>
          </div>
        )} */}
      </div>

      <InviteUserModal onClose={handleActionClick} />
    </section>
  );
};
