import React from "react";
import editImg from "../assets/pricing/edit_img.svg";
import originalImg from "../assets/pricing/n__DSC_0074_Original.jpeg";
import aiImg from "../assets/pricing/n__DSC_0074_AI.jpeg";
import submitImg from "../assets/pricing/n__DSC_0074_Submit.jpeg";
import acceptIcon from "../assets/pricing/service_circle.svg";
import declineIcon from "../assets/pricing/service_close.svg";
import plusIcon from "../assets/services/plus.svg";
import { useTranslation } from "react-i18next";
import ReactCompareImage from "react-compare-image";
import upscale_before from "../assets/services/upscale-before.jpg";
import upscale_after from "../assets/services/upscale-after.jpg";
import resize from "../assets/services/resize.png";
import watermark_before from "../assets/services/watermark-before.jpeg";
import watermark_after from "../assets/services/watermark-after.jpeg";
import white_balance_before from "../assets/services/white-balance-before.jpeg";
import white_balance_after from "../assets/services/White-Balance-Adjustment-After.jpg";
import brightness_before from "../assets/services/Brightness-and-contrast-adjustment-Before.jpg";
import brightness_after from "../assets/services/Brightness-and-contrast-adjustment-After.jpg";
import horizontal_vertical_before from "../assets/services/Horizontal-And-Vertical-Distortion-Modification-Before.jpg";
import horizontal_vertical_after from "../assets/services/Horizontal-And-Vertical-Distortion-Modification-After.jpg";
import len_curvature_before from "../assets/services/Lens-Curvature-Adjustment-Before.jpg";
import len_curvature_after from "../assets/services/Lens-Curvature-Adjustment-After.jpg";
import sky_before from "../assets/services/Blue-sky-setting-Before.jpg";
import sky_after from "../assets/services/Blue-sky-setting-After.jpg";
import blur_before from "../assets/services/Blurring-Personal-Information-Before.jpg";
import blur_after from "../assets/services/Blurring-Personal-Information-After.jpg";
import lawn_color_before from "../assets/services/Change-The-Color-Of-The-Lawn-Before.jpg";
import lawn_color_after from "../assets/services/Change-The-Color-Of-The-Lawn-After.jpg";
import fire_burning_before from "../assets/services/Burning-the-fireplace-Before.jpg";
import fire_burning_after from "../assets/services/Burning-the-fireplace-After.jpg";
import water_color_before from "../assets/services/Replacement-Of-Pool-Water-Surface-Before.jpg";
import water_color_after from "../assets/services/Replacement-Of-Pool-Water-Surface-After.jpg";
import tv_insert_before from "../assets/services/TV-Screen-Image-Insert-Before.jpg";
import tv_insert_after from "../assets/services/TV-Screen-Image-Insert-After.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { icon } from "@fortawesome/fontawesome-svg-core";
export const services = [
  {
    name: "service_upscale",
    description: "service_upscale_des",
    before: upscale_before,
    after: upscale_after,
    isFree: true,
    isAi: true,
    isProfessional: false,
    status: "service_beta",
    extraInfo: "service_free",
    link: "#",
    icon: "bi-file-earmark-arrow-up-fill",
  },
  {
    name: "service_resize",
    description: "service_resize_des",
    before: resize,
    after: resize,
    isFree: true,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_free",
    link: "#",
    icon: "bi-arrows-fullscreen",
  },
  {
    name: "service_watermark",
    before: watermark_before,
    after: watermark_after,
    description: "service_watermark_des",
    isFree: true,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_free",
    link: "#",
    icon: "bi-file-earmark-excel-fill",
  },
  {
    name: "service_white_balance",
    before: white_balance_before,
    after: white_balance_after,
    description: "service_white_balance_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-brightness-low",
    isDefault: true,
  },
  {
    name: "service_brightness",
    before: brightness_before,
    after: brightness_after,
    description: "service_brightness_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-triangle-half",
    isDefault: true,
  },
  {
    name: "service_horizontal_vertical",
    before: horizontal_vertical_before,
    after: horizontal_vertical_after,
    description: "service_horizontal_vertical_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_active",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-rulers",
    isDefault: true,
  },
  {
    name: "service_len_curvature",
    before: len_curvature_before,
    after: len_curvature_after,
    description: "service_len_curvature_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_beta",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-headset-vr",
  },
  {
    name: "service_sky",
    before: sky_before,
    after: sky_after,
    description: "service_sky_des",
    isFree: false,
    isAi: true,
    isProfessional: true,
    status: "service_beta",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-cloudy-fill",
  },
  {
    name: "service_blur",
    before: blur_before,
    after: blur_after,
    description: "service_blur_des",
    isFree: false,
    isAi: false,
    isProfessional: true,
    status: "service_coming",
    extraInfo: "service_extra_charge",
    link: "#",
    extra: 0.4,
    icon: "bi-eye-slash-fill",
  },
  {
    name: "service_lawn_color",
    before: lawn_color_before,
    after: lawn_color_after,
    description: "service_lawn_color_des",
    isFree: false,
    isAi: false,
    isProfessional: true,
    status: "",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-palette-fill",
  },
  // {
  //   name: "service_wallpaper_color",
  //   before: wall_color_before,
  //   after: wall_color_after,
  //   description: "service_wallpaper_color_des",
  //   isFree: false,
  //   isAi: false,
  //   isProfessional: true,
  //   status: "",
  //   extraInfo: "service_extra_charge",
  //   link: "#",
  //   extra: 0.3,
  // },
  {
    name: "service_fire_burning",
    before: fire_burning_before,
    after: fire_burning_after,
    description: "service_fire_burning_des",
    isFree: false,
    isAi: false,
    isProfessional: true,
    status: "",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-fire",
  },
  {
    name: "service_water_surface",
    before: water_color_before,
    after: water_color_after,
    description: "service_water_surface_des",
    isFree: false,
    isAi: false,
    isProfessional: true,
    status: "",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-droplet-half",
  },
  {
    name: "service_tv_insert",
    before: tv_insert_before,
    after: tv_insert_after,
    description: "service_tv_insert_des",
    isFree: false,
    isAi: false,
    isProfessional: true,
    status: "",
    extraInfo: "service_included",
    link: "#",
    icon: "bi-tv-fill",
  },
  // {
  //   name: "service_item_removal",
  //   before: item_remove_before,
  //   after: item_remove_after,
  //   description: "service_item_removal_des",
  //   isFree: false,
  //   isAi: false,
  //   isProfessional: true,
  //   status: "",
  //   extraInfo: "service_extra_charge",
  //   link: "#",
  //   extra: 2,
  // },
  // {
  //   name: "service_hdr",
  //   isFree: false,
  //   isAi: false,
  //   isProfessional: true,
  //   status: "",
  //   extraInfo: "service_extra_charge",
  //   link: "#",
  // },
];

export const Services = (props) => {
  const { t } = useTranslation();
  const [activeService, setActiveService] = React.useState(undefined);
  const renderServiceItem = (service, mode) => {
    const isActive = service.name === activeService;
    if (mode === "all") {
      return isActive ? (
        <tr
          key={service.name}
          colspan={"4"}
          style={{ position: "relative", overflow: "hidden" }}
        >
          <td className="active">
            <div className="name">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setActiveService(undefined);
                }}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faAngleDoubleUp}
                  style={{
                    animation: "moveUp 1s ease-in-out infinite",
                    marginRight: "10px",
                  }}
                />
                {t(service.name)}
              </span>
            </div>
            <div className="detail row">
              <div className="compare_image col col-sm-12 col-md-6">
                <ReactCompareImage
                  leftImage={service.before}
                  rightImage={service.after}
                />
              </div>
              <div className="col col-sm-12 col-md-6">
                <div className="service-description">
                  <span className="description">{t(service.description)}</span>
                  {service.extra && (
                    <button className="extra">
                      <img src={plusIcon} alt="" />
                      {/* <p
                        // style={{
                        //   textDecoration: "line-through",
                        //   fontSize: "1.8rem",
                        //   margin: "0",
                        //   marginRight: "5px",
                        //   marginTop: "5px",
                        //   fontWeight: "400",
                        // }}
                      > */}
                      {t(`service_` + service.name + `_fee`)}
                      {/* </p>{" "} */}
                      {/* <>{t(`service_service_promotion`)}</> */}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </td>
          {/* {service.extra && (
            <div
              style={{ width: "auto", fontSize: "2rem" }}
              className="ribbon right-ribbon"
            >
              {t(`service_promotion`)}
            </div>
          )} */}
        </tr>
      ) : (
        <tr key={service.name}>
          <td style={{ position: "relative", overflow: "hidden" }}>
            <div className="name">
              {service.extraInfo.length > 0 && (
                <p className="type">{t(service.extraInfo)}</p>
              )}
              <span
                href={service.link}
                onClick={() => setActiveService(service.name)}
                target="_blank"
                rel="noreferrer"
                className="service-name-title"
              >
                {t(service.name)}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  onClick={() => setActiveService(service.name)}
                  style={{
                    marginLeft: "5px",
                    color: "#3678f7",
                    fontSize: "15px",
                  }}
                />
                {service.extra && (
                  <div className="tag price_tag">
                    +
                    <span
                    // style={{
                    //   textDecoration: "line-through",
                    //   marginRight: "5px",
                    // }}
                    >
                      {t(`service_service_blur_fee`)}
                    </span>{" "}
                    {/* <>{t(`service_service_promotion`)}</> */}
                  </div>
                )}
              </span>
            </div>
            {/* {service.extra && (
              <div
                style={{ width: "auto", fontSize: "1rem" }}
                className="ribbon right-ribbon"
              >
                {t(`service_promotion`)}
              </div>
            )} */}
          </td>
          <td>
            {service.isFree ? (
              <img src={acceptIcon} alt="" />
            ) : (
              <img src={declineIcon} alt="" />
            )}
          </td>
          <td className={service.status.length > 0 ? "ai-support" : ""}>
            {service.isAi ? (
              <img src={acceptIcon} alt="" />
            ) : (
              <img src={declineIcon} alt="" />
            )}
            {service.status.length > 0 && (
              <div className={"tag " + service.status}>{t(service.status)}</div>
            )}
          </td>
          <td>
            {service.isProfessional ? (
              <img src={acceptIcon} alt="" />
            ) : (
              <img src={declineIcon} alt="" />
            )}
          </td>
        </tr>
      );
    }
    return isActive ? (
      <tr
        key={service.name}
        colspan={"2"}
        style={{ position: "relative", overflow: "hidden" }}
      >
        <td className="active">
          <div className="name">
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveService(undefined);
              }}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faAngleDoubleUp}
                style={{
                  animation: "moveUp 1s ease-in-out infinite",
                  marginRight: "10px",
                }}
              />
              {t(service.name)}
            </span>
          </div>
          <div className="detail row">
            <div className="compare_image col col-sm-12 col-md-6">
              <ReactCompareImage
                leftImage={service.before}
                rightImage={service.after}
              />
            </div>
            <div className="col col-sm-12 col-md-6">
              <div className="service-description">
                <span className="description">{t(service.description)}</span>
                {service.extra && (
                  <button className="extra">
                    <img src={plusIcon} alt="" />
                    {/* <span
                    // style={{
                    //   textDecoration: "line-through",
                    //   marginRight: "5px",
                    //   marginTop: "5px",
                    //   marginLeft: "5px",
                    //   color: "white",
                    // }}
                    > */}
                    {t(`service_` + service.name + `_fee`)}
                    {/* </span>{" "} */}
                    {/* <>{t(`service_service_promotion`)}</> */}
                  </button>
                )}
              </div>
            </div>
          </div>
        </td>
        {/* {service.extra && (
          <div
            style={{ width: "auto", fontSize: "2rem" }}
            className="ribbon right-ribbon"
          >
            {t(`service_promotion`)}
          </div>
        )} */}
      </tr>
    ) : (
      <tr key={service.name}>
        <td style={{ position: "relative", overflow: "hidden" }}>
          <div className="name">
            {service.extraInfo.length > 0 && (
              <span className="type">{t(service.extraInfo)}</span>
            )}
            <span
              onClick={() => setActiveService(service.name)}
              href={service.link}
              target="_blank"
              rel="noreferrer"
            >
              {t(service.name)}
              <FontAwesomeIcon
                icon={faCircleInfo}
                style={{
                  marginLeft: "5px",
                  color: "#3678f7",
                  fontSize: "15px",
                }}
              />
              {service.extra && (
                <div className="tag price_tag">
                  +
                  {/* <span
                    // style={{
                    //   textDecoration: "line-through",
                    //   marginRight: "5px",
                    // }}
                  > */}
                  {t(`service_service_blur_fee`)}
                  {/* </span>{" "} */}
                  {/* <>{t(`service_service_promotion`)}</> */}
                </div>
              )}
            </span>
          </div>
          {/* {service.extra && (
            <div
              style={{ width: "auto", fontSize: "1rem" }}
              className="ribbon right-ribbon"
            >
              {t(`service_promotion`)}
            </div>
          )} */}
        </td>
        {mode === "free" && (
          <td>
            {service.isFree ? (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img src={acceptIcon} alt="" />
            ) : (
              <img src={declineIcon} alt="" />
            )}
          </td>
        )}
        {mode === "ai" && (
          <td className={service.status.length > 0 ? "ai-support" : ""}>
            {service.isAi ? (
              <img src={acceptIcon} alt="" />
            ) : (
              <img src={declineIcon} alt="" />
            )}
            {service.status.length > 0 && (
              <div className={"tag " + service.status}>{t(service.status)}</div>
            )}
          </td>
        )}
        {mode === "professional" && (
          <td>
            {service.isProfessional ? (
              <img src={acceptIcon} alt="" />
            ) : (
              <img src={declineIcon} alt="" />
            )}
          </td>
        )}
      </tr>
    );
  };
  return (
    <div id="services" className="text-center">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>{t(`service_title`)}</span>
          </div>
        </h2>
        <div className="txt">
          <span>{t(`service_des_1`)}</span>
        </div>
        <div className="seePrice">
          <span>{t(`service_see_price_des`)}</span>
          <div
            className="btn"
            onClick={() => {
              ReactGA.send({
                hitType: "pageview",
                page: "pricing",
                title: "Redirect to page",
              });
            }}
          >
            <a href="#pricing">
              <span>{t(`service_see_price_btn`)}</span>
            </a>
          </div>
        </div>
        {/* feature table for desktop */}
        <table className="priceListPC">
          <thead>
            <tr>
              <th className="feature">
                <div className="inner">
                  <div className="ttl">
                    <span>{t(`service_feature`)}</span>
                  </div>
                </div>
              </th>
              <th className="free">
                <div className="inner">
                  <div className="ttl">
                    <span>{t(`service_free`)}</span>
                  </div>
                </div>
              </th>
              <th className="ai">
                <div className="inner">
                  <div className="tag">
                    <span>{t(`service_recommend`)}</span>
                  </div>
                  <div className="ttl">
                    <span>{t(`service_ai_only`)}</span>
                  </div>
                </div>
              </th>
              <th className="team">
                <div className="inner">
                  <div className="tag">
                    <span>{t(`service_option`)}</span>
                  </div>
                  <div className="ttl">
                    <div>
                      <span>{t(`service_professional_1`)}</span>
                      <br />
                      <span>{t(`service_professional_2`)}</span>
                      <br />
                      <span>{t(`service_professional_3`)}</span>
                    </div>

                    <div className="tag price_tag">
                      <span>+{t(`service_professional_price`)}</span>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {services.map((service) => {
              return renderServiceItem(service, "all");
            })}
          </tbody>
        </table>
        {/* feature table for mobile */}
        <div className="priceListSP">
          <details className="details">
            <summary className="spList01 details__summary">
              <div className="free">
                <div className="inner">
                  <div className="ttl">
                    <span>{t(`service_free`)}</span>
                  </div>
                </div>
              </div>
            </summary>
            <table className=" priceList details__content">
              <tbody>
                {services.map((service) => {
                  return renderServiceItem(service, "free");
                })}
              </tbody>
            </table>
          </details>
          <details className="details">
            <summary className="spList02 details__summary">
              <div className="ai">
                <div className="inner">
                  <div className="tag">
                    <span>{t(`service_recommend`)}</span>
                  </div>
                  <div className="ttl">
                    <span>{t(`service_ai_only`)}</span>
                  </div>
                </div>
              </div>
            </summary>
            <table className="priceList details__content">
              <tbody>
                {services.map((service) => {
                  return renderServiceItem(service, "ai");
                })}
              </tbody>
            </table>
          </details>
          <details className="details" open="">
            <summary className="spList03 details__summary">
              <div className="team">
                <div className="inner">
                  <div className="tag">
                    <span>{t(`service_option`)}</span>
                  </div>
                  <div className="ttl">
                    <span>
                      {" "}
                      +{" "}
                      {t(`service_professional_1`) +
                        " " +
                        t(`service_professional_2`) +
                        " " +
                        t(`service_professional_3`)}
                    </span>
                    <div className="tag price_tag">
                      <span>+{t(`service_professional_price`)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </summary>
            <table className=" priceList details__content">
              <tbody>
                {services.map((service) => {
                  return renderServiceItem(service, "professional");
                })}
              </tbody>
            </table>
          </details>
        </div>
        <div className="block">
          <div className="inner">
            <div className="left">
              <img src={editImg} alt="" />
            </div>
            <div className="right">
              <div className="ttl">
                <span>{t(`service_professional_title`)}</span>
              </div>
              <div className="txt">
                <p>{t(`service_professional_sub1`)}</p>
                <p>{t(`service_professional_sub2`)}</p>
              </div>
            </div>
          </div>
          <div className="ph">
            <div className="item">
              <img src={originalImg} alt="" />
              <span>{t(`service_origin`)}</span>
            </div>
            <div className="item">
              <img src={aiImg} alt="" />
              <span>{t(`service_ai_only`)}</span>
            </div>
            <div className="item">
              <img src={submitImg} alt="" />
              <span>{t(`service_submit`)}</span>
            </div>
          </div>
          <div className="ribbon right-ribbon">
            + {t(`service_professional_price`)}
          </div>
        </div>
      </div>
    </div>
  );
};
