import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import step1 from "../assets/inviteUser/ai-feature.png";
import step2 from "../assets/inviteUser/free-credit.png";
import step3 from "../assets/inviteUser/massive-order.png";
import step4 from "../assets/inviteUser/no-paid.png";
import { CLIENT_PORTAL } from "../constants";
import ReactGA from "react-ga4";
import originalImg from "../assets/inviteUser/0_Original.jpg";
import brightnessImg from "../assets/inviteUser/1_Brightness.jpg";
import perspectiveImg from "../assets/inviteUser/2_Perspective.jpg";
import blurImg from "../assets/inviteUser/3_Blur.jpg";
import skyImg from "../assets/inviteUser/4_Sky.jpg";
import submitImg from "../assets/inviteUser/5_Professional.jpg";
import { FloatingText } from "./FloatingText";
const ai_process_steps = [
  {
    name: "original",
    image: originalImg,
  },
  {
    name: "brightness",
    image: brightnessImg,
  },
  {
    name: "horizontal_vertical",
    image: perspectiveImg,
  },
  {
    name: "blur",
    image: blurImg,
  },
  {
    name: "sky",
    image: skyImg,
  },
];
export const InviteUserModal = (props) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [isScanning, setIsScanning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsScanning(true);
      setTimeout(() => {
        setCurrentStep((prev) => (prev + 1) % ai_process_steps.length);
      }, 500);
      setTimeout(() => {
        setIsScanning(false);
      }, 1000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleRegister = () => {
    window.location.href = CLIENT_PORTAL + "/auth/login";
    ReactGA.event({
      category: "Landing Page",
      action: "Click",
      label: "Get Login Button",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const keyPoints = [
    {
      name: "explore_more",
      color: "#f23674",
    },
    {
      name: "free_credit",
      color: "#ffa03a",
    },
    {
      name: "massive_order",
      color: "#1cacf3",
    },
  ];
  return (
    <div
      className="modal fade"
      id="inviteUserModal"
      tabIndex="-1"
      role="dialog"
      data-bs-backdrop="static"
      aria-labelledby="inviteUserModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header d-flex">
            <span className="modal-title">
              <span class="c"> {t(`invite_user_header_1`)}</span>
              <br />
              {t(`invite_user_header_2`)}
            </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row modal-sub-invite-title">
              {t(`invite_user_header_3`)}
            </div>
            <div className="row">
              <div id="free_usage_service" className="col col-md-5 col-xs-5">
                <div className="ph">
                  <div className="item" style={{ position: "relative" }}>
                    <div
                      // className={`image-container ${
                      //   isScanning ? "scanning" : ""
                      // }`}
                      style={{ position: "relative" }} // Add this style
                    >
                      <img
                        src={originalImg}
                        alt=""
                        id="ai_img"
                        style={{
                          borderRadius: "10px",
                        }}
                      />
                      {/* {currentStep > 0 && (
                      <FloatingText
                        text={t(
                          `service_${ai_process_steps[currentStep].name}`
                        )}
                      />
                    )} */}
                    </div>
                    <span>{t("service_origin")}</span>
                  </div>
                  <div className="item">
                    <img
                      src={skyImg}
                      alt=""
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                    <span>{t(`service_ai_only`)}</span>
                  </div>
                  <div className="item">
                    <img
                      src={submitImg}
                      alt=""
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                    <span>{t(`service_submit`)}</span>
                  </div>
                </div>
              </div>
              <div className="col col-md-7 col-xs-7">
                {keyPoints.map((item, index) => (
                  <div key={index}>
                    <div
                      className={
                        "col-12 key-point-box " + "key-point-box-" + index
                      }
                    >
                      <div className="box__title">
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            backgroundColor: item.color,
                            color: "white",
                            marginRight: "10px",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {index + 1}
                        </span>
                        {t(`invite_user_${item.name}`)}
                      </div>
                      <span className="box__des">
                        {t(`invite_user_${item.name}_des`)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center invite-user-modal-btn-group">
                <button
                  className="btn btn-primary btn-lg mx-2"
                  style={{
                    fontWeight: "bold",
                    background: "linear-gradient(90deg, #007bff, #0056b3)",
                  }}
                  onClick={handleRegister}
                >
                  {t("free_register")}
                </button>
                <button
                  className="btn btn-outline-secondary btn-lg"
                  style={{
                    fontWeight: "500",
                    color: "#000",
                    background:
                      "linear-gradient(to right, #c0def6 0%, #e5e4fe 100%)",
                  }}
                  aria-label="Close"
                  data-dismiss="modal"
                  onClick={props.onClose}
                >
                  {t("skip_for_now")}
                </button>
              </div>
              <div className="col-12 text-center action-note">
                {t("invite_user_action_note")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
